import React from "react"
import {
  FaCode,
  FaShopify,
  FaGlobe,
  FaServicestack,
  FaStarHalfAlt,
} from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Web Development Services",
    text: `Static site generation setting new standards, full stack headless CMS development, PWA or mobile app the choice is yours`,
    slug: "/services/development",
  },
  {
    id: 2,
    icon: <FaShopify className="service-icon" />,
    title: "Partner Development",
    text: `A headless, full stack or static e-commerce store, use shopify as a back-end payment service. logo and themes from partners.`,
    slug: "/services/partner/",
  },
  {
    id: 3,
    icon: <FaStarHalfAlt className="service-icon" />,
    title: "Managed Services",
    text: `One stop shop for management of all client IT services including domain names, hosting, email services and more!.`,
    slug: "/services/clientServices",
  },
  {
    id: 4,
    icon: <FaGlobe className="service-icon" />,
    title: "Business Operations",
    text: `Mobile apps for IOS and Android platforms. The future is here now and your reach to the far corners of the world relised`,
    slug: "/services/operations",
  },
  {
    id: 5,
    icon: <FaServicestack className="service-icon" />,
    title: "Consultancy Services",
    text: `One stop shop for management of all client IT services including domain names, hosting, email services and more!.`,
    slug: "/services/operations",
  },
]

export default data
