import React from "react"
import { graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero } from "../../containers"
import { BreadCrumb, TitleV2 } from "../../components"
import Services from "../../constants/services"
import ServicesCrumbs from "../../constants/BreadCrumbs/ServicesCrumbs"

//import Price from "../../components/Sections/Service_Sections/price"
//import Features from "../../components/Sections/Service_Sections/features"
//import ExtraFetures from "../../components/Sections/Service_Sections/extraFetures"

const ServicesPage = ({ data }) => {
  const image = getImage(
    data.strapiServicePage.image.localFile.childImageSharp.gatsbyImageData
  )
  //console.log("Services page - data", Services)
  return (
    <section>
      <Hero image={image} title="AduDev Services" />
      <BreadCrumb crumbs={ServicesCrumbs} />
      <div className="uk-container uk-margin-small-top">
        <div className="uk-margin-remove-bottom">
          <TitleV2
            title={data.strapiServicePage.title}
            styleClass="underline"
          />
        </div>

        <div className="uk-margin-remove-bottom">
          {data.strapiServicePage.text}
        </div>

        <div className="uk-section design-card">
          <div className="uk-container">
            <div
              className="uk-child-width-1-3@m uk-grid-match uk-text-center"
              uk-grid="true"
            >
              {Services.map(service => {
                const { id, icon, title, text, slug } = service

                return (
                  <Link key={id} to={slug} alt={title} title={title}>
                    <div className=" design-card uk-text-center">
                      <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline">
                        <span
                          className="uk-text-large"
                          style={{ color: "#ff7f00" }}
                        >
                          {icon}
                        </span>

                        <h3 className="uk-card-title uk-margin-remove-top">
                          {title}
                        </h3>
                        <div className="underline" />
                        <p>{text}</p>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesPage

export const query = graphql`
  {
    strapiServicePage {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
      text
    }
  }
`
